#endSlides{
    height: 100%;
    width: 100%;
    background-color: #414042;
    /* background-image:linear-gradient(to bottom left,  #7b6bb7, #b6a8db); */
}
.swiper {
    width: 100vw !important;
    height: 100vh !important;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    width: 70%;
  }
  
 .swiper-pagination-bullets{
    margin-top: 0%;
}
.swiper-pagination-bullet{
    width: 2em !important;
    background-color: #D1D3D4 !important;
    border-radius: 2px !important;
}
.swiper-pagination-bullet-active{
    width: 2em !important;
    background-color: #F1F2F2 !important;
    border-radius: 2px !important;
}
.swiper-pagination{
    top:5% !important;
}  
.swiper-pagination-horizontal{
    height: 2em !important;
}
.swiper-slide-active{
    width: 100% !important;
}

.swiper-button-prev {
    z-index: 100;
    border-radius:100%; 
    color:rgba(0, 0, 0, 0);
    padding: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 2em;
    background-image: url("../img/storyL.png") !important;
  }
  .swiper-button-next {
    z-index: 100;
    border-radius:100%; 
    color:rgba(0, 0, 0, 0); 
    padding: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 2em;
    background-image: url("../img/storyR.png") !important;
  }
  .swiper-button-next:hover{
    background-image: url("../img/storyRH.png") !important;
  }
  .swiper-button-prev:hover{
    background-image: url("../img/storyLH.png") !important;
  }


  .border-blanco{
    margin-top: 5% !important;
    border: solid 1px #fff;
    padding:1.5em;
    height: 90% !important;
    width: 85%;
    margin-left: 7%;
  }

@media only screen and (min-width: 768px) {
    .swiper {
        max-width: 50vw !important;
        max-height: 100vh !important;
      }
    .endslide{
        width: 70% !important;
      }
    .border-blanco{
      border: none;
        padding:1.5em;
        height: 90% !important;
    }  
    .swiper-button-next {
      right: 10%;}
    .swiper-button-prev {
      left: 10%;}
/* .swiper-pagination{
        top: 10% !important;
    }
    .swiper-pagination-bullet{
        width: 3em;
    }
    .swiper-pagination-active{
        width: 3em;
    } */
    }