.bg-none{
  background-color: transparent !important;
}
.b-negro{
  border:solid 2px black;
}
.w-100vw{
  width: 100vw !important;
}
.w-90vw{
  width: 90vw !important;
}
.w-80vw{
  width: 80vw !important;
}
.h-100vh{
  height: 100vh;
}
.h-90vh{
  height: 90vh;
}
.h-50vh{
  height: 50vh;
}
.h-content{
  height: fit-content !important;
}
.root { 
  overflow:hidden !important;
}
.App{
  overflow: hidden !important;
}
.nombreActivo{
  width: 93vw;
  background-color: #F1F2F2;
}
.bg-none{
  background-color: transparent;
}
/* width */
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
b{
  font-weight: bold !important;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important; 
  border-radius: 10px !important;
}
ul li{
  list-style: none;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: black !important; 
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9F8BEA !important; 
}

@import url("https://p.typekit.net/p.css?s=1&k=ryc8beu&ht=tk&f=14032.14034.14036.21512&a=2153897&app=typekit&e=css");

  @font-face {
  font-family:"aktiv-grotesk";
  src:url("https://use.typekit.net/af/28158e/00000000000000003b9b4066/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/28158e/00000000000000003b9b4066/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/28158e/00000000000000003b9b4066/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;
  }
  
  @font-face {
  font-family:"aktiv-grotesk";
  src:url("https://use.typekit.net/af/62681e/00000000000000003b9b406a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/62681e/00000000000000003b9b406a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/62681e/00000000000000003b9b406a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;
  }
  
  @font-face {
  font-family:"aktiv-grotesk";
  src:url("https://use.typekit.net/af/76d40d/00000000000000003b9b4064/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/76d40d/00000000000000003b9b4064/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/76d40d/00000000000000003b9b4064/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:300;
  }
  
  @font-face {
  font-family:"aktiv-grotesk";
  src:url("https://use.typekit.net/af/cee6e5/00000000000000003b9b4062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("woff2"),url("https://use.typekit.net/af/cee6e5/00000000000000003b9b4062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("woff"),url("https://use.typekit.net/af/cee6e5/00000000000000003b9b4062/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:200;
  }
  
  .tk-aktiv-grotesk { font-family: "aktiv-grotesk",sans-serif; } 
   
  @import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600&family=Playfair+Display:ital@1&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600&family=Playfair+Display&display=swap');



/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v23/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw0aXpsog.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v23/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw9aXpsog.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v23/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw2aXpsog.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v23/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw3aXpsog.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v23/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw5aXo.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v28/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfFunDTbtPY_Q.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v28/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfFunDYbtPY_Q.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v28/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfFunDZbtPY_Q.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v28/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfFunDXbtM.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}



.gradWP{
  background: linear-gradient(227deg, #F0F0F0,#F0F0F0, #9f8bea);
}
.gradRP{
  background: linear-gradient(302deg,  #9f8bea, #6484e8);
}
.gradBlack{
  background: linear-gradient(-45deg, #414042, black, black);
}
hr{
  border-top: 2px solid #9F8BEA !important;
  opacity: 100 !important;
}

body{
  font-family: "aktiv-grotesk",sans-serif !important;
  font-weight:normal;
  font-weight: 400;
  margin: 0%;
  padding: 0%;
}
h1{
  font-size: 3.5em !important;
  line-height: 85% !important;
  font-weight: 600 !important;
}
h2{
  font-size: 2em !important;
  font-weight: 800 !important;
  line-height: 85%;
}
p{
  font-size: 1em !important;
  line-height: initial;
  overflow-wrap: break-word;
}
a{
  color: white !important;
  text-decoration: none !important;
}
a:hover{
  color:#9F8BEA !important;
  cursor: pointer;
}
.floatR{
  float: right;
}
.floatL{
  float: left;
}
.full{
  min-height: 100vh;
  max-width: 100vw;
}
.playfair{
  font-family: 'Playfair Display', serif;
}
.playfairItalic{
  font-family: 'Playfair Display', serif;
  font-style: italic;
}
.aktiv{
  font-family: "aktiv-grotesk",sans-serif !important;
}
.container{
  padding: 0% !important;
  max-width: 100% !important;
}
.rosita{
  color:#ECA9FC}
.crema{
  color:#F1F2F2 !important}
.gris{
  color:#d1d3d4 !important}
.negro{
  color: #000000 !important;}
.azul{
  color:#6484E8 !important}
.lila{
  color:#9F8BEA !important}
.blanco{
  color: #fff !important;
}
.celeste{
  color:#90B7CE !important}
.bg-rosita{
  background-color:#ECA9FC !important}
.bg-crema{
  background-color:#F1F2F2 !important}
.bg-gris{
  background-color:#d1d3d4 !important}
  .bg-grisOsc{
    background-color:#414042 !important}
.bg-negro{
  background-color: #000000 !important;}
.bg-blanco{
  background-color: #fff !important;}
.bg-azul{
  background-color:#6484E8 !important}
.bg-lila{
  background-color:#9F8BEA !important}
.bg-offBlack{
  background-color:#171617!important
}
.under-blanco{
  border-bottom: 1px solid #fff;
}  
.under-rosita{
  border-bottom: 1px solid #ECA9FC;
}
.under-lila{
  border-bottom: 1px solid #9F8BEA;
}
.under-azul{
  border-bottom: 1px solid #6484E8;
  padding-bottom: 1px;
}
.under-negro{
  border-bottom: 1px solid #000000;
  padding-bottom: 1px;
}
.over-negro{
  border-top: 1px solid #000000;
  padding-top: 1px;
}
.blanco-borde-negro{
  border: 2px solid #000000;
  background-color: white;
}
.border-lila{
  border: solid 1px #9F8BEA;
  padding:1.5em;
}

.border-negro-redondeado{
  border: solid 2px #000000;
  border-radius: 10px;
}
.text-center{
  text-align: center !important;
}
.text-left{
  text-align: left !important;
}
.text-right{
  text-align: right !important;
}
.logoIcon{
  height: 50px;
  width: auto;
}
.logo{
  height: 20px;
  width: auto;
}
.icons{
  height: 30px;
  width: auto;
}
.footer{
  padding: 2em;
  font-size: 1em;
}
.footer a{
  display: block;
  color: white;
}

.footer a:hover{
  display: block;
  color: #9F8BEA;
}
.m-0{
  margin: 0 !important;
}
.-mb-5{
  margin-left: -5% !important;
}
.-mb-10{
  margin-left: -10% !important;
}
.-ml-5{
  margin-left: 5% !important;
}
.-ml-10{
  margin-left: 10% !important;
}
.-ml-15{
  margin-left: 15% !important;
}
.ml-15{
  margin-left: 15% !important;
}
.ml-45{
  margin-left: 45% !important;
}
.ml-7{
  margin-left: 7% !important;
}
.ml-10{
  margin-left: 10% !important;
}
.-mt-1{
  margin-top: -1.1em !important;
}
.-mt-2{
  margin-top: -2em !important;
}
.-mt-5{
  margin-top: -5% !important;
}
.-mt-10{
  margin-top: -10% !important;
}
.-mt-15{
  margin-left: -15% !important;
}
.mb-10{
  margin-bottom: 10% !important;
}
.mt-8{
  margin-top: 8% !important;
}
.mt-10{
  margin-top: 10% !important;
}
.mt-15{
  margin-top: 15% !important;
}
.mt-20{
  margin-top: 20% !important;
}
.mt-25{
  margin-top: 25% !important;
}
.mt-35{
  margin-top: 35% !important;
}
.mt-45{
  margin-top: 45% !important;
}
.mt-50{
  margin-top: 55% !important;
}
.mt-55{
  margin-top: 55% !important;
}
.mt-65{
  margin-top: 65% !important;
}
.mt-75{
  margin-top: 75% !important;
}

.mt-150{
  margin-top: 150% !important;
}
.pt-15{
  padding-top: 15%;
}
.opacity-05{
  opacity: 0.5;
}

.f05{
  font-size: 0.5em !important;
}
.f06{
  font-size: 0.6em !important;
}
.f08{
  font-size: 0.8em !important;
}
.f1{
  font-size: 1em !important;
}
.f1-2{
  font-size: 1.2em !important;
}
.f1-5{
  font-size: 1.5em !important;
}
.f2{
  font-size: 2em !important;
}
.f2-5{
  font-size: 2.5em !important;
}
.f3{
  font-size: 3em !important;
}
.f4{
  font-size: 4em !important;
}
.f5{
  font-size: 5em !important;
}
.f6{
  font-size: 6em !important;
}
.f10{
  font-size: 10em !important;
}
.f15{
  font-size: 10em !important;
}
.misc{
   height: fit-content !important;
}

.hide-sm{
  display: none;
}
#logoFooter{
  width: -webkit-fill-available !important;
  padding-right: 0.5em !important;
}
#acerca{
  background-color: #171617;
}


.fw-100{
  font-weight:100 !important ;
}
.lh1-5{
  line-height: 1.5 !important;
}
.lh1-3{
  line-height: 1.3 !important;
}
.pl-8{
  padding-left: 8% !important;
}
.mr-3{
  margin-right: 3% !important;
}
.mr-5{
  margin-right: 5% !important;
}
.mr-10{
  margin-right: 10% !important;
}
.pointer{
  cursor: pointer;
}
.ml-0{
margin-left: 0% !important;
}


.pulse{
  animation: pulse 1.5s infinite;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
.wiggle{
  animation: wiggleA 4s infinite;
}
@keyframes wiggleA {
  0% { transform: rotate(0deg); }
 80% { transform: rotate(0deg); }
 85% { transform: rotate(5deg); }
 95% { transform: rotate(-5deg); }
100% { transform: rotate(0deg); }
}

#iconsMd{
  display: none !important;
}
@media only screen and (min-width: 768px) {
  .w-md-100vw{
    width: 100vw !important;
  }
  .w-md-90vw{
    width: 90vw !important;
  }
  .w-md-80vw{
    width: 80vw !important;
  }
  .h-md-100vh{
    height: 100vh;
  }
  .h-md-90vh{
    height: 90vh;
  }
  .h-md-50vh{
    height: 50vh;
  }
  #iconsMd{
    display:block !important;
  }
  .wiggle-md{
    animation: wiggleA 4s infinite;
  }
  .footer{
    font-size: 0.8em;
  }
  .mr-md-3{
    margin-right: 3% !important;
  }
  .mx-md-0{
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
  .ml-md-25{
    margin-left: 25% !important;
  }
  .mr-md-0{
    margin-right: 0% !important;
  }
  .f-md-3{
    font-size: 3em !important;
  }
  .p-0{
    padding: 0;
  }
  .p-md-10{
    padding: 10% !important;
  }
  .pl-md-0{
    padding-left: 0% !important;
  }
  .pl-md-1{
    padding-left: 1% !important;
  }
  .pl-md-10{
    padding-left: 10% !important;
  }
  .hide-sm{
  display: block !important;
  }
  .hide-md{
    display: none !important;
  }
  .faveTool{
    text-align: left;
  }
  .md-align-left{
    text-align: left !important;
  }
  .md-align-right{
    text-align: right !important;
  }
  h1{
    font-size: 6em !important;  
  }
  .pt-md-15{
    padding-top: 15%;
  }
  .mt-md-8{
    margin-top: 8% !important;
  }
  .mt-md-10{
    margin-top: 10% !important;
  }
  .mt-md-15{
    margin-top: 15% !important;
  }
  .mt-md-20{
    margin-top: 20% !important;
  }
  .mt-md-25{
    margin-top: 25% !important;
  }
  .mt-md-35{
    margin-top: 35% !important;
  }
  .mt-md-45{
    margin-top: 45% !important;
  }
  .mt-md-75{
    margin-top: 75% !important;
  }
  #footerIcons{
    width: 70% !important;
    margin-left: 2% !important;
  }
}

.row{
  max-width: 100vw !important;
}

/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */

@media only screen and (min-width: 1200px) {

  .f1-xl{
    font-size: 1em !important;
  }
  .mt-xl-30{
    margin-top: 30%;
  }
  .mt-xl-35{
    margin-top: 35%;
  }
  .mt-xl-40{
    margin-top: 40%;
  }
}
