.texto{
    background-color: #F1F2F2;
    width: 100vw;
}
.destacado{
    font-weight: 600;
    color: #9F8BEA;
    font-size:1.5em !important ;
}
a{
    color: #9F8BEA !important;
}
p{
    font-weight: 300;
}
h3{
    font-weight: bold;
    background-color:#F1F2F2;
}
.titles{
  padding-top: 0.5em;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 2em;
  align-self: flex-start;
}
.wrapper{
    /* height: 100% !important; */
    overflow: visible !important;
}
.AppOV{
    overflow: visible !important;
}
#download:hover{
    background-color: white !important;
    border: solid 2px #9F8BEA !important;
}
@media only screen and (min-width: 768px) {
    .titles{
        top: 5px;
        padding-bottom: 8px;
      }
}
