.visible {
  /* display: flex; */
  top: 0;
  transition: top 0.4s ease-out;
}
.redirect{
  min-height: 6em;
  margin: 1em;
}
.redirect:hover{
  background-color: #9F8BEA !important;
  color: white !important;
}
.redirect-texto{
  line-height: 1.2;
  min-height: 7em;
  padding: 1.2em;
}
.redirect img{  
  height: 1.5em;
  position: relative;
  bottom: 0%;
  margin-left: 45%;
  margin-bottom: -1em !important;
}

.hidden {
  /* display: none; */
  top: -20%;
  transition: top 0.4s ease-out;
}
table tr{
  border: solid 2px;
  border-collapse: collapse;
  color: #9F8BEA;
  border-width: 2px;
}
h1{
  font-size: 3rem;
}

.herramientaImg{
  width: -webkit-fill-available !important;
}
.ej img{
  height: fit-content;
  margin: 0.5em;
}
.herramientaImg:hover{
  background-color: white;
}
.contenedorCaja{
  width: 80%;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.contenedorCaja a{
  padding: 0.5em !important;
}
.contenedorCaja a:hover{
  color: white !important;
}
#btnGuia{
  width: 73%;
  border: solid 2px #000000;
  border-radius: 5px;
  padding: 0.5em 2em 0.5em 2em !important;
  position: fixed;
  top: 88%;
  left: 49%;
  transform: translate(-50%, -50%);
  text-align: center;
}
#btnGuia img{
  height: 1em;
}
.bottomText{
  font-size: xx-large;
  text-align: center;
  font-weight: 900;
  position: fixed;
  top: 98%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#tituloCaja{
  display: none;
}
.banner{
  border: solid 2px black;
}
.banner img{
  width: -webkit-fill-available !important;
}

.menu-tool-content{
  background-color:#9F8BEA;
  overflow-wrap: normal;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  z-index: 50;
  padding-left: 2em;
}
.toolBody{
  width: 100% !important;
  overflow: hidden;
}
.activa{
  display: block;
}
.noactiva{
  display: none;
}  
.tituloMenu{
  margin-top: 10% !important;
  margin-bottom: solid 1px #9F8BEA!important;
  text-transform: uppercase !important;
}
.redirects{
  margin-top: 5%;
  background-color: #9F8BEA;
}
.redirects .row{
  margin:0%
}
#guiaRow{
  margin-left: 0.03%;
}
@media only screen and (min-width: 768px) {
  .redirects{
    background-color: transparent;
    margin-left: 25% !important;
  }
  .noactiva{
    display: block;
  }
  .menu-tool-content{
    position: fixed;
    margin-top: 8%;
    left:2%;
    height: auto;
    width: 15%;
    padding-left: 1em;
    background-color: transparent !important;
    z-index: 110;
  }
  .posFixed{
    margin-top: 8%;
  }
  .posUp{
    margin-top: -4%
  }
  .menuHerramienta{
    position: fixed;
    z-index: 500;
  }
  .banner{
    margin-left: 20% !important;
    background-image: url('../img/Sbg1.png'),url('../img/Sbg2.png');
    background-repeat: no-repeat;
    background-size: 25%,25%;
    background-position:5% center,95% center ;
  }
  .banner img{
    width: 15% !important;
    margin-left: 40%;
  }
  .herramientaImg{
    width: 60%;
  }
  #btnGuia{
    top:85%;
    background-color: white;
  } 
  .contenedorCaja{
    top:60%;
  }
  #tituloCaja{
    display: block;
    width: 75%;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (min-width: 768px) {
  .posUp{
    margin-top: 2%;
  }
}