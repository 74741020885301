.row{
    margin: 0% !important;
}
#vid{
    height: 90vh;
    width: 100vw;
}
.wraps{
    background-color:#38a72c73 !important;
    height: 100vh;
    width: 100vw;
    z-index: 10;
}
#situacionFull{
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background: linear-gradient(302deg, #F0F0F0, #F0F0F0,#9F8BEA);
    background-size: 800% 800%;
    -webkit-animation: GradAnimation 6s ease infinite;
    -moz-animation: GradAnimation 6s ease infinite;
    animation: GradAnimation 6s ease infinite;
}
.gif{
    height: 200px;
    background-image: url('../img/ejMobile.gif');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-left: 5%;
}
.activo1{
    font-weight: bold;
}
.activo2{
font-weight: bold;
}
.activo3{
font-weight: bold;
}
.block{
    display: block;
}
.hidden{
    display: none;
}
.activa{
    display: block;

    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
.noactivas{
     display: none;
}
#start-quiz{
    cursor: pointer;  
    position: absolute;
    bottom: 5%;
}

.title{
    display: block;
}
.situacion{
  margin-top: 25%;
  padding: 1em;
}

.endSlide{
    padding: 10%;
    height: 100%;
    width: 100%;
    background-image:linear-gradient(to bottom left,  #9F8BEA, #BBA1FC);
}
.btnRedo{
    background-color: white;
    border: solid 2px #9F8BEA ;
    color: #9F8BEA !important;
    border-radius: 10px;
    padding: 0.5em 2em;
    cursor: pointer;
}

.btnQuiz{
    position: fixed;
    bottom: 5%;
    right: 4%;
    z-index: 1;
    /* padding: 1em; */
    cursor: pointer;
}
#siguenteBtn  {
    /* background-color: white; */
    /* border: solid 2px #9F8BEA ; */
    color: #9F8BEA !important;
    border-radius: 10px;
    padding: 0.5em 2em;
    margin-right: 0.5em;
}
/* #siguenteBtn:hover  {
    background-color: #9F8BEA;
    color: white !important;
} */

#flechaBtn img{
    height: 2em;
}
#flechaBtn img:hover{
  background-color: white;
  border-radius: 100%;
}
#descripcionFave{
    font-weight: 300;
}

.amiguePf{
    background-image: linear-gradient(to top right,  #9f8bea,#9f8bea, #6484e8);    
}
.xahiPf{
    background-image:linear-gradient(to top right,  #BBA1FC, #8F79E3,#8F79E3 );
}
.anteojosPf{
    background-image:  linear-gradient(to top right, #BBA1FC, #E095FF);
}

.amigue{
    background:linear-gradient(302deg, #9f8bea,#9f8bea, #6484e8);
    background-size:100% 100%;
}
.xahi{
    background-image:linear-gradient(to top right, #BBA1FC, #8F79E3,#8F79E3 ) !important;
    background-size: 100% 100%;

}
.anteojos{
    background-image:linear-gradient(to top right,  #BBA1FC, #E095FF) !important;
    background-size: 100% 100%;

}

.amiguec{
    background: url('../img/amigue1.png'), linear-gradient(302deg, #9f8bea,#9f8bea, #6484e8);
    background-size: 60% 40%, 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    /* -webkit-animation: GradAnimation 4s ease infinite;
    -moz-animation: GradAnimation 4s ease infinite;
    animation: GradAnimation 4s ease infinite; */
}
.xahic{
    background-image:url('../img/xahi1.png'),linear-gradient(to top right, #BBA1FC, #8F79E3,#8F79E3 ) !important;
    background-size: 60% 40%, 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.anteojosc{
    background-image:url('../img/antejos1.png'),linear-gradient(to top right,  #BBA1FC, #E095FF) !important;
    background-size: 60% 40%, 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}
@-webkit-keyframes GradAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes GradAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes GradAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}


.amigueF{
    background-image: url('../img/illus/end1.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size:contain;
}
.xahiF{
    background-image: url('../img/illus/end2.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size:contain;
}
.anteojosF{
    background-image: url('../img/illus/end3.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size:contain;
}
#verResultados{
    border: solid 2px white;
    color: white !important;
    border-radius: 10px;
}
#verResultados:hover{
    border: solid 2px #BBA1FC;
    background-color: white;
    color: #BBA1FC !important;
    border-radius: 10px;
}
input[type="range"] {
    -webkit-appearance: none;
    height: 2em;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5em;
    background-size: 70% 100%;
    background-repeat: no-repeat;
  }
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height:  2em;
    width:  2em;
    border-radius: 50%;
    background-color: #1b3c9e65;
  }
#twtacc{
    width:8em;
    margin-left: 3%;
}
#twtdate{
    width:18em;
    margin-left: 3%;
}
.comilla{
    color: #9F8BEA !important;
    background-image: url('../img/comilla.png');
    background-repeat: no-repeat;
    background-position:right;
    background-size: contain;
}
/* .procesando{
    background-image: url('../img/bub.png');
    background-repeat: no-repeat;
    background-position: 25% 100%;
    background-size:50%;
} */
/* .aca{
    background-image: url('../img/smile.png'),linear-gradient(to bottom left,  #9F8BEA, #BBA1FC);
    background-repeat: no-repeat;
    background-position: center 50%;
    background-size:10%, 100%;
} */
.botText{
    position: absolute;
    left:  33%;
    bottom:10% ;
}
.ruido{
    background-image: url('../img/curly1.png'),url('../img/curly2.png'), linear-gradient(to bottom left,  #9F8BEA, #BBA1FC);
    background-repeat: no-repeat;
    background-position: 70% 15%, 45% 80%;
    background-size:20%, 15%, 100%;
}
.quizImg img{
    width: -webkit-fill-available !important;
    opacity: 50%;
    margin-top: 20%
}
.options{
    position: fixed;
    bottom: 0%;
    height: 45%;
    overflow-y: scroll;
    width: 100%;
    z-index: 400;
    background-color: #C5B9F2;
    margin: 0%;
}
.option{
    background-color:#9F8BEA;
    margin-top: 0.5em;
    padding: 1.5em;
    width: 95%;
    margin-left: 2%;
    font-size: 2em;
    font-weight: 300;
}
.option:hover{
    background-color:black;
}
.fondoSituacion{
    display: none;
}
#ayuda{
    position: absolute;
    bottom: 5%;
    left: 5%;
    height: 2em;
}
#stepGuia{
    position: absolute;
    top: 3%;
    right:5%;
    text-align: end;
    color: #58595B !important;
}

#startIntro{
    text-align: center;
}

h1{
    font-size: 3em !important;
}
#faveIndex{
    background-color: black;
    border-radius: 100px;
    height: 3em;
    width: 3em;
    margin-left: 45%;
}

#modalInfo{
    width: 90%;
    background-color: #d1d3d4;
    border-radius: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 500;
    border: solid 2px black; 
    text-align: center;
}
#modalInfo img{
    width: 100%; 
}
.blackScreen{
    background-color: black;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 40%;
    z-index: 100;
}
#segure{
    width: 90%;
    text-align: center;
    background-color: #d1d3d4;
    border-radius: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 500;
    border: solid 2px black; 
    font-weight: bold;
}
#segure a{
    cursor: pointer;
}
#segure a:hover{
    cursor: pointer;
    text-decoration: underline !important;
}
#volver{
    width: inherit;
    position: absolute;
    top: 3%;
    left:5%;
}
#volver img{
    margin-right: 1em;
    height: 2em;
}
#volver:hover{
    font-weight: bolder;
    cursor: pointer;
}
#volver img:hover{
    background-color: white;
    border-radius: 100%;
    margin-right: 1em;
}
#ayuda:hover{
    background-color: white;
    border-radius: 50px;
    cursor: pointer;
}
#ayuda img{
    height: 2em !important;
}
#resultTitle{
    font-size: 2em !important;
}
#start-quiz img{
    width:auto;
    height: 40px;
}
@media only screen and (min-width: 768px) {
    #modalInfo{
        width: 50%;
    }
    .sigStart{
        height: 30px;
        width: auto !important;
    }
    .flechaEnBorde{
        display: none;
    }
    .gif{
        height: 400px;
        background-image: url('../img/ejDesktop.gif');
    }
    #segure{
        width: 50%;
        text-align: center;
        border-radius: 15px;
        position: absolute;
        z-index: 500;
    }
    #resultTitle{
        font-size: 3em !important;
    }
    #volver{
        position: absolute;
        top: 3%;
        left:5%;
    }
    h1{
        font-size: 5em !important;
    }
    #faveIndex{
        background-color: black;
        border-radius: 100px;
        height: 3em;
        width: 3em;
    }
    #stepGuia{
        top: 5%;
        right:5%;
    }
    #startIntro{
        text-align: left;
        font-size: 1.4em;
    }
    #ayuda{
        position: absolute;
        top: 83%;
        left: 5%;
    }
    .twitter{
        padding-top: 0.5em;
        background-position:10% 8%, 12% 100%;
        background-size: 20%, 55%;
    }
    /* #input1
    {
        transform: rotate(270deg);
        position: fixed;
        top: 40%;
        left: 5%;
    } */
    .ruido{
        background-position: 20% 25%, 75% 80%;
        background-size:10%, 5%, 100%;
    }
    .procesando{
        background-position: 90% 90%;
        background-size: 20%;
    }
    .aca{
        background-image: url('../img/smile.png'), url('../img/D.png'),linear-gradient(to bottom left,  #9F8BEA, #BBA1FC);;
        background-repeat: no-repeat;
        background-position: 15% 80%, 85% 15%;
        background-size:5%,5%,100%;
    }
    .endSlide{
        padding: 0%;
        width: 375px;
        height: 667px;
    }
    .fondoSituacion{
        display: block;
    }
    .fondoSituacion img{
        max-height: 40%;
        position: fixed;
        top: 70%;
        left: 35%;
        transform: translate(-50%, -50%);
        padding: 2em;
        z-index: 0;
    }
    #siguenteBtn  {
        font-size: 1em;
    }

    .title{
        display: none;
    }   
    .quizImg{
        margin-top:0 !important;
        height: 5em;
        position: fixed;
        top: 35%;
        left: 75%;
        transform: translate(-50%, -50%);
        padding: 2em;
    }    
    .situacion{
        position: fixed;
        top: 45%;
        left: 30%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        font-size: 1.2em !important;
    }
    .options{
        position: fixed;
        top: 45%;
        left: 75%;
        transform: translate(-50%, -50%);
        height: 40%;
        overflow-y: scroll;
        width: 35%;
        border: solid 2px black;
        padding: 2em;
        cursor: pointer;
        background-color:#F1F2F2 !important
    }

    .amiguePf{
        background-image:url('../img/wiggle1.png'), url('../img/wiggle.png'), linear-gradient(to right,  #9F8BEA, #6484E8, #6484E8, #6484E8);
        background-repeat: no-repeat;
        background-position: 0% 10%, 100% 20%;
    }   
    .xahiPf{
        background-image: url('../img/wiggle1.png'), url('../img/wiggle.png') ,linear-gradient(to right, #9F8BEA, #9F8BEA, #6484E8,#6484E8 )!important;
        background-repeat: no-repeat;
        background-position: 0% 10%, 100% 20%;
    }
    .anteojosPf{
        background-image: url('../img/wiggle1.png') ,url('../img/wiggle.png') ,  linear-gradient(to right,  #9F8BEA, #9F8BEA, #9F8BEA, #6484E8) !important;        
        background-repeat: no-repeat;
        background-position: 0% 10%, 100% 20%;

      }

    .btnQuiz{
        position: fixed;
        bottom: 10%;
        right: 5%;
        z-index: 1;
        padding: 1em;
        cursor: pointer;
    }
    #twtacc{
        width:8em !important;
        margin-bottom: 0%;
    }
    #twtdate{ 
        margin-top: 0%;
        margin-left: 0% !important;
    }

    #start-content{
        width: 100vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    #start-quiz{
        position: absolute;
        top: 85%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

