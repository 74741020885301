p{
    font-size: 1.5em;
}
.container{
    margin: 0% !important;
}
h1{
    z-index: 50;
}
.nota{
    font-size: x-small !important;
    z-index: 0;
    line-height: 80%;
    background-color: white;
  }
.linea{
    width: auto !important;
}
#home1{
    margin: 0 0 -10% 0;
}
#home2{
    margin: -20% 0 -10% 20%;
}
#home3{
    margin: -20% 0 0% 0%
}
.homemisc img{
    height: 40px !important;
}
.landing1{
    min-height: 100vh;
    width: 100vw;
}
.landing2{
    height: auto;
    width: 100vw;
}
#landing3{
    height: auto;
    width: 100vw;
    padding-bottom: 2em;
}
#filledStar{
    margin:-10% 0 0 40%;
}
#star{
    margin-left: 70%;
    margin-top: -15%;
}
#bocaRosa{
    margin-top: 5%;
    margin-left: 35%;
}
/* #noNeutral{
    margin-top: 5%;
    margin-bottom: -3%;
    line-height: 90% !important;
} */
#comoHablamos{
    line-height: 90% !important;
}
#quiz{
    /* min-height: 100vh; */
    width: 100vw;
    padding-bottom: 35%;
    /* background-image: url('../img/curlySpeechW.png'), url('../img/xgris.png');
    background-repeat: no-repeat;
    background-size: 40%,10%;
    background-position: 35% 90%,80% 15%; */
}
#texto{
    padding-bottom: 35%;
    width: 100vw;
}
/* #texto{
    background-image: url('../img/Xserif.png'), url('../img/interrogacion2.png'),url('../img/A.png');
    background-repeat: no-repeat;
    background-size: 15%,15%,25%;
    background-position: 5% 40%,90% 15%,95% 98%;
} */
/* @keyframes animateCaja { */
	/* from { background-position: 20px 20px, 30px 80px, 0 0; }
	to { background-position: 30px 30px, 40px 90px, 10% 10%; } */
    /* 0% { background-position: 15% 90%,20% 25%,64% 50%,95% 85% }
   30% { background-position: 16% 89%,21% 25%,63% 51%,94% 86% }
   60% { background-position: 15% 90%,20% 25%,64% 50%,95% 85% }
   90% { background-position: 14% 91%,19% 25%,65% 49%,96% 84% }
  100% { background-position: 15% 90%,20% 25%,64% 50%,95% 85% }
} */
		
#caja{    
    padding-bottom: 35%;
    width: 100vw;
    /* background-image: url('../img/diccionario.gif'), url('../img/bocaLila.png'),url('../img/bocaLineaRosa.png'),url('../img/blackWiggle.png');
    background-repeat: no-repeat;
    background-size: 15%,15%,15%,6%;
    background-position: 15% 90%,20% 25%,65% 50%,95% 85%; */
    /* animation: animateCaja 8s infinite; */
}

#cuestionasmobile,#cuestionasdesk{
    font-size: 1.5em !important;
    font-weight: 600 !important;
    border:solid 2px;
    line-height: 1.2 !important;
}
#cuestionasdesk{
    display: none;
}
.flechaRedir{
    height: 40px;
}
@media only screen and (min-width: 768px) {
    .landing-center{
        position: relative;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 10%);
    }
    #landing1{
        height: auto;
        width: 100vw;
    }
    #landing2{
        width: 100vw;
    }
    #landing3{
        height: 100vh;
        width: 100vw;
        
    }
    #cuestionasdesk{
        display: block;
    }
    #cuestionasmobile{
        display: none;
    }
    .nota{
        display: none;
    }
    .linea{
        display: none;
    }
    #home1{
        position: absolute;
        top:45%;
        left: 0%;
        width: 400px;
        z-index: 0;
    }
    #home2{
        margin: 0;
        padding: 0%;
        position: absolute;
        top:55%;
        left: 54%;
        width: 350px;
        z-index: 0;
    }
    #home3{
        position: absolute;
        top:100%;
        left: 35%;
        width: 400px;
        margin: 0em;
        z-index: 21;
    }
    #bajada{
        position: absolute;
        width: 350px;
        padding: 1em;
        padding-top: 4em;
        padding-right: 3em;
        height: 400px;
        top: 70%;
        left: 40%;
        font-size: 1em;
        background-image: url('../img/bajada.png');
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 20;
    }
    #feminismo{
        position: absolute;
        width: 350px;
        padding: 1em;
        padding-top: 4em;
        padding-left: 2.2em;
        height: 400px;
        top: 130%;
        left: 15%;
        font-size: 1em;
        background-image: url('../img/fembub.png');
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 22;
    }
    #lenguaje{
        position: absolute;
        width: 350px;
        padding: 1em;
        padding-top: 4em;
        padding-right: 3em;
        height: 400px;
        top: 140%;
        right: 3%;
        font-size: 1em;
        background-image: url('../img/lenbub.png');
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 22;
    }
    #lineaLengua{
        position: absolute;
        top:160%;
        left:45%
    }
    
    .bocaGris{
        position: absolute;
        top: 170%;
        left: 8%;
    }
    #bocaRosa{
        position: absolute;
        top:170%;
        left:47%
    }
    #bocaGris{
        position: absolute;
        top:170%;
        left:15%
    }
    #bubWiggle{
        position: absolute;
        top:100%;
        left:15%
    }
    #filledStar{
        position: absolute;
        top:245%;
        left:25%
    }
    /* #noNeutral{
        position: absolute;
        top:215%;
        left:28%;
        width:40%;
    } */
     #quiz{
        min-height: inherit !important;
        padding-bottom:8%;
    /*    background-image: url('../img/curlySpeechW.png'), url('../img/at.png'),url('../img/xgris.png');
        background-repeat: no-repeat;
        background-size: 20%,5%,5%;
        background-position: 20% 80%,80% 40%,20% 20%;
    */
    } 
    #caja{
        min-height: inherit !important;
        padding-bottom:8%;
        /* background-image: url('../img/diccionario.gif'), url('../img/bocaLila.png'),url('../img/bocaLineaRosa.png'),url('../img/blackWiggle.png');
        background-repeat: no-repeat;
        background-size: 8%,7%,7%,7%;
        background-position: 20% 80%,20% 25%,80% 80%,95% 15%; */
    }
    #texto{
        min-height: inherit !important;
        padding-bottom:8%;
        /* background-image: url('../img/Xserif.png'), url('../img/interrogacion2.png'),url('../img/A.png');
        background-repeat: no-repeat;
        background-size: 5%,5%,15%;
        background-position: 5% 40%,90% 15%,95% 98%; */
    }

}
@media only screen and (min-width: 1200px) {
    #landing3{
        height: 100vh;
        padding-bottom: 0;
    }
    #cuestionasdesk{
        padding-left: 3em;
        padding-right: 3em;
    }
    #home1{
        top:45%;
        left: 12%;
    }
    #home2{
        left: 65%;
    }
    #home3{
        top:95%;
    }
    #lenguaje{
        top: 135%;
        right: 15%;
    }
    #feminismo{
        top: 130%;
        left: 20%;
    }
    #bocaRosa{
        top:165%;
        left:45%
    }
    .bocaGris{
        top: 170%;
        left: 20%;
    }
    .mt-xl-15{
        margin-top: 10% !important;
    }
}

@media only screen and (min-width: 1300px) {
    
    #home1{
        top:35%;
        left: 20%;
    }
    #home2{
        top:35%;
        left: 60%;
    }
    #home3{
        top:75%;
    }
    #bajada{
        top: 50%;
        left: 43%;;
    }
    #lenguaje{
        top: 95%;
        right: 20%;
    }
    #feminismo{
        top: 90%;
        left: 20%;
    }
    #bocaRosa{
        top:115%;
        left:40%
    }
    .bocaGris{
        top: 120%;
        left: 15%;
    }
    #rowquestionas{
        margin-top: -10% !important;
    }
    #cuestionasdesk{
        margin-top: 0 !important;
    }
}


@media only screen and (min-width: 1600px) {
    
    #home1{
        top:35%;
        left: 20%;
    }
    #home2{
        top:35%;
        left: 60%;
    }
    #home3{
        top:65%;
    }
    #bajada{
        top: 50%;
        left: 43%;;
    }
    #lenguaje{
        top: 95%;
        right: 25%;
    }
    #feminismo{
        top: 90%;
        left: 20%;
    }
    #bocaRosa{
        top:105%;
        left:40%
    }
    .bocaGris{
        top: 115%;
        left: 15%;
    }
    #rowquestionas{
        margin-top: -10% !important;
    }
    #cuestionasdesk{
        margin-top: 0 !important;
    }
}

