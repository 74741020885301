.vis{
  animation-name:downup;
  animation-duration: 1.5s;
  z-index: 1100;
}
.hid{
  animation-name:test;
  animation-duration: 1.5s;
  top: -20%;
}
@keyframes test {
  from{
    top:0%;
  }
  to{
    top:-20%;
}
}
@keyframes updown {
  from{
    transform: translateY(0%);
  }
  to{
    transform: translateY(-200%);
}
}
@keyframes downup {
  from{
    transform: translateY(-200%);
  }
  to{
    transform: translateY(0%);
}
}
.menu{
  width: 100vw;
  display: block;
  padding: 0% !important;
  margin: 0% !important;
}
.menu-content{
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 100;
  display: block;
  width: 100vw;
  height: 100vh;
}
.a-blanco .menu-content a{
  color: white !important;
  line-height: 1.2;
}
.a-hover-blanco .menu-content a:hover{
  color: white !important;
}
.activa{
  display: block;
}
.noactiva{
  display: none;
}
#burger{
  display: block;
  width: 5%;
  z-index: 2000 !important;
}
#flag{
  width: 5%;
  z-index: 1000 !important;
}
.progress-container {
  width: 100vw;
  height: 5px;
  background: #ccc;
  position: fixed;
  top: 0%;
  left:0%;
  z-index: 200;
}
.progress-bar {
  height: 5px;
  background: #9F8BEA !important;
  width: 0%;}

header {
  padding: 10px;
  display: flex;
  justify-content: center;
  top: 0;
  position: fixed;
  width: 100vw;
}
#cruz img{
  height: 1em;
}

.menu-content a{
  display: block;
  color: white;
  font-weight: bold !important;
  font-size: 1.3em
}

@media only screen and (min-width: 768px) {
  #burger{
    display: none;
  }  
  #cruz{
    display: none;
  }
  header{
    padding: 30px !important;
  }
  .menu-content{
    position: absolute;
    display: block !important;
    width: 78%;
    height: 3vh;
    margin-left: 22%;
  }  
  .menu-content a{
    color: black !important;
    text-align:left;
    font-size: 0.8em;
  }
  .menu-content a:active{
    color: #9F8BEA !important;
  }
  .menu-content a:hover{
    color: #9F8BEA !important;
  }
  .noactiva{
    display: block !important;
  }
  .activa{
    width: 50%;
    float: right;
    height: 100vh;
    padding-top: 1em;
    background-color: transparent !important;
  }
}
